import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

export default props => {
    const { course, index } = props

    return (
        <Link to={course.url} className="block relative z-10 group max-w-5xl mx-auto md:h4 lg:-72 overflow-hidden border bg-white transition duration-150 hover:shadow-xl shadow rounded-md transform duration-100 scale-100 active:scale-95">
                <div className="h-full flex flex-col md:flex-row px-2 sm:px-4">
                    <div
                        className={`${
                            index % 2 === 0 ? 'md:order-first' : 'md:order-last'
                        } text-center md:w-6/12 relative pt-2 sm:py-4`}>
                        <Img
                            className="absolute inset-0 w-full h-full shadow group-hover:shadow-xl rounded overflow-hidden"
        objectFit="fit"
                            fluid={course.cardImg.childImageSharp.fluid}
                            alt="course"
                        />
                    </div>
                    <div
                        className={`${
                            index % 2 === 0 ? 'sm:pl-4 pr-3' : 'pl-2 pr-3 sm:pr-6'
                        } py-2 flex-1 flex flex-col`}>
                        <div className="sm:flex sm:justify-between sm:items-baseline sm:flex-row-reverse">
                            <div className="flex items-center text-gray-600 mt-2 sm:mt-0 text-sm">
                                <svg
                                    className="w-4 h-4"
                                    viewBox="0 0 28 28"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.2167 22.4L18.2 14L23.2167 5.6L25.6667 14L23.2167 21.4ZM12.1333 21.5833L5.83333 15.4H15.8667L20.8833 23.8L12.1333 21.5833ZM12.1333 6.3L20.8833 4.08333L15.8667 12.4833H5.71667C5.71667 12.6 12.1333 6.3 12.1333 6.3ZM24.9667 0L13.5333 2.91667L11.7833 5.83333H8.4L0 14L8.4 22.1667H11.7833L13.4167 25.0833L24.85 28L27.8833 16.9167L26.25 14L28 11.0833L24.9667 0Z" />
                                </svg>
                                <span className="pl-1 font-bold ">Unity</span>
                            </div>
                            <h2 className="py-2 text-xl font-bold text-gray-800">{course.title}</h2>
                        </div>
                        <p className="text-gray-800 text-sm flex-1">{course.description}</p>
                        <div className="sm:flex sm:justify-between py-2">
                            <ul className="sm:flex w-full text-teal-700 text-xs font-medium">
                                <li className="capitalize py-1 flex items-center">
                                    <div className="flex-center h-7 w-7 rounded-full bg-teal-100">
                                        <svg
                                            className="h-4 w-4 text-teal-500"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                                            />
                                        </svg>
                                    </div>
                                    <span className="ml-2">{course.difficulty} Difficulty</span>
                                </li>
                                <li className="capitalize py-1 sm:ml-4 flex items-center">
                                    <div className="flex-center h-7 w-7 rounded-full bg-teal-100">
                                        <svg
                                            className="h-4 w-4 text-teal-500"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                    </div>
                                    <span className="ml-2">{course.videoDuration} Video</span>
                                </li>
                                <li className="capitalize py-1 sm:ml-4 flex items-center">
                                    <div className="flex-center h-7 w-7 rounded-full bg-teal-100">
                                        <svg
                                            className="h-4 w-4 text-teal-500"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                                            />
                                        </svg>
                                    </div>
                                    <span className="ml-2">{course.episodeCount} Episodes</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Link>
    )
}

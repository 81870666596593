import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import HorizontalCourseCard from '../components/horizontal-course-card.js'

const Pagination = props => {
    return (
        <div className="lg:w-3/5 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
                <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                    Previous
                </button>
                <button className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                    Next
                </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm leading-5 text-gray-700">
                        Showing
                        <span className="px-1 font-medium">1</span>
                        to
                        <span className="px-1 font-medium">3</span>
                        of
                        <span className="px-1 font-medium">3</span>
                        results
                    </p>
                </div>
                <div>
                    <span className="relative z-0 inline-flex shadow-sm">
                        <button
                            type="button"
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150">
                            <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    fillRule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                        <button
                            type="button"
                            className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                            1
                        </button>
                        <button
                            type="button"
                            disabled={true}
                            className="cursor-not-allowed -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-400 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                            2
                        </button>
                        <button
                            type="button"
                            className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150">
                            <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default props => {
    return (
        <Layout>
            <div className="sm:mt-20">
                <h1 className="font-semibold text-center mt-6">All Courses</h1>
                <ul className="px-2 mt-4 sm:mt-8">
                    {props.data.allCourse.edges.map((e, i) => {
                        return (
                            <li key={e.node.id} className="mb-10">
                                {e.node.isFree && (
                                    <div className="max-w-5xl mx-auto w-full flex z-0">
                                        <span className="w-full sm:w-auto text-center uppercase tracking-widest bg-teal-200 z-0 text-teal-700 -mb-1 rounded-t text-xs font-semibold py-2 sm:pt-2 sm:pb-3 px-3">
                                            Free Course
                                        </span>
                                    </div>
                                )}
                                <HorizontalCourseCard index={i} course={e.node} />
                            </li>
                        )
                    })}
                </ul>
            </div>
        </Layout>
    )
}

export const courseListQuery = graphql`
    query courseListQuery($skip: Int!, $limit: Int!) {
        allCourse(filter:{public:{eq: true}}, limit: $limit, skip: $skip, sort: { fields: releaseDate, order: DESC }) {
            edges {
                node {
                    id
                    title
                    price
                    description
                    isFree
                    status
                    difficulty
                    releaseDate
                    videoDuration
                    url
                    episodeCount
                    cardImg {
                        childImageSharp {
                            fixed(width: 320) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }
`
